.section {
  padding-top: 60px;
  padding-bottom: 60px;
  width: 100%;
  align-items: center;
}
.section.purple {
  background-color: var(--color-purple-light);
}
.section.lexbase {
  border-bottom: 3px dashed var(--color-purple-light);
  margin-bottom: 20px;
}
.section.lexbase .cols .two-col:first-child {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.section.lexbase img {
  max-width: 250px;
  width: 100%;
}
.section .inner {
  max-width: 1366px;
}
.section.partners .inner {
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.section.info-puff .cols {
  align-items: center;
}
img.partner {
  max-height: 50px;
  margin-top: 50px;
}
.hero {
  margin-bottom: 20px;
  padding: 60px 0;
  background-color: var(--color-purple-light);
  width: 100%;
}
.hero .images {
  flex-direction: row;
  justify-content: center;
}
.hero .images img {
  width: 20%;
  max-width: 300px;
  align-self: center;
}
.hero .images img.left {
  position: relative;
  right: -40px;
  z-index: 1;
}
.hero .images img.left.outer {
  position: relative;
  right: -80px;
  z-index: 0;
}
.hero .images img.middle {
  position: relative;
  z-index: 2;
  width: 23%;
  max-width: 340px;
}
.hero .images img.right {
  position: relative;
  right: 40px;
  z-index: 1;
}
.hero .images img.right.outer {
  position: relative;
  right: 80px;
  z-index: 0;
}
.hero .apple,
.hero .google {
}
.app-btn-wrp {
  width: 100%;
  max-width: 360px;
  margin-top: 60px;
}
.app-btn-wrp .inner {
  max-width: 360px;
  width: 100%;
  flex-direction: row;
  align-self: center;
  justify-content: space-between;
}
.app-btn-wrp p {
  align-self: center;
  font-weight: 500;
  font-size: 18px;
}
.app-btn-wrp .app-btn {
  max-height: 50px;
  cursor: pointer;
}
.app-btn-wrp a {
  cursor: pointer;
}
.app-btn-wrp a:hover {
  border-color: transparent;
}
.puffs {
  background-color: var(--color-purple-light);
  width: 100%;
  align-items: center;
  padding-right: 40px;
  padding-left: 40px;
}
.puffs .cols .three-col {
  background-color: #fff;
  padding: 20px 20px 30px;
  border-radius: 20px;
  min-height: 300px;
}
.puffs .cols .three-col img {
  border-radius: 20px;
  margin-bottom: 10px;
}
.discount.home {
  background-color: var(--color-purple-main);
  padding: 20px 25px;
  border-radius: 12px;
  max-width: 600px;
  margin-bottom: 90px;
}
.discount.home h4 {
  color: #fff;
}
.discount.home p {
  margin-bottom: 0;
  color: #fff;
}
@media all and (max-width: 520px) {
  .section {
    padding: 20px 20px;
  }
  .section.lexbase {
    padding-bottom: 10px;
    padding-top: 40px;
    margin-bottom: 50px;
  }
  .section.lexbase .cols .two-col:first-child {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .section.lexbase .cols .two-col:first-child svg {
    margin: 20px 0;
  }
  .hero {
    overflow: hidden;
  }
  .discount.home {
    margin-bottom: 40px;
  }
  .hero .images img {
    width: 22%;
    margin-top: 30px;
  }
  .hero .images img.middle {
    width: 25%;
  }
  .hero .images img.left {
    left: 15px;
  }
  .hero .images img.left.outer {
    left: 30px;
  }
  .hero .images img.right {
    right: 15px;
  }
  .hero .images img.right.outer {
    right: 30px;
  }
  .hero h1 {
    font-size: 30px;
  }
  .app-btn-wrp {
    margin-top: 30px;
  }
}
