.main-menu {
  border-bottom: 1px solid var(--color-purple-light);
  width: 100%;
  background-color: #fff;
}
.main-menu.fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}
.main-menu .inner {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #fff;
  padding: 0 20px;
  width: 100%;
  max-width: 1366px;
  align-self: center;
}
.main-menu .menu {
  flex-direction: row;
  align-items: center;
}
.main-menu .menu .links {
  flex-direction: row;
}
.main-menu .menu a {
  margin-right: 30px;
  text-decoration: none;
  color: #333;
}
.main-menu .menu a.active {
  font-weight: 500;
  margin-right: 30px;
  color: var(--color-purple-main);
  border-color: var(--color-purple-main);
}
.mobile-menu-btn {
  display: none;
}

.lang-btns {
  flex-direction: row;
  align-items: center;
}

.lang-btns button {
  background: none;
  border: none;
  padding: 0 8px;
  text-decoration: "none";
  font-weight: "400";
  color: "#333";
}

.lang-btns button.active {
  text-decoration: underline;
  font-weight: 600;
  color: #78408f;
}

@media all and (max-width: 920px) {
  .mobile-menu-btn {
    display: flex;
    padding: 10px;
    width: 54px;
    height: 50px;
    justify-content: center;
    align-items: center;
    background: none;
    border: 1px solid var(--color-purple-light);
    border-radius: 8px;
    margin-left: 10px;
  }
  .mobile-menu-btn.active {
    background: var(--color-purple-light);
  }
  .main-menu .menu .links {
    display: none;
  }
  .lang-btns {
    display: none;
  }
  .lang-btns.mobile {
    display: block;
  }
  .lang-btns.mobile button {
    color: #fff;
  }
  .lang-btns button.active {
    text-decoration: underline;
    font-weight: 600;
    color: #fff;
  }

  .lang-btns svg path {
    color: #fff;
  }
}
