.how-it-works {
}
.how-it-works .section {
  /* align-items: unset; */
}
.slider {
  /* align-items: unset; */
  width: 100%;
  max-width: 600px;
  margin-bottom: 100px;
}
.slick-slide,
.slick-slide div {
  width: 100%;
  max-width: 600px;
  width: 100%;
}

.slide {
  display: flex !important;
  align-items: center;
  padding: 0 40px;
}

.slide img {
  width: 50%;
  margin-bottom: 30px;
}

.slide h2 {
  font-weight: 700;
}
.slide .bullets {
  flex-direction: row;
  align-items: flex-start;
}
.slide .bullets path {
  color: var(--color-purple-main);
}
.slick-arrow {
  top: 250px;
}
.slick-next {
  right: 0;
}
.slick-prev {
  display: none !important;
}
.slick-arrow::before {
  color: var(--color-purple-main);
  opacity: 1;
  font-size: 30px;
}
.how-it-works .two-col {
}
.two-col.free {
  padding: 25px;
  background-color: var(--color-purple-light);
  border-radius: 20px;
}
.two-col.payed {
  padding: 25px;
  background-color: var(--color-purple-pale);
  border-radius: 20px;
}
.two-col.payed .cols {
  margin-bottom: 0;
}
.price {
  color: var(--color-purple-main);
  position: relative;
}
/* .price::before {
  content: "";
  width: 120px;
  height: 2px;
  position: absolute;
  top: 17px;
  z-index: 1;
  background-color: #000;
} */
.price span {
  color: var(--color-purple-main);
  font-size: 24px;
  font-weight: 600;
  position: relative;
  top: 1px;
}
.discount {
  background-color: var(--color-purple-light);
  padding: 20px 25px;
  border-radius: 12px;
}
.discount p {
  margin-bottom: 0;
}
@media all and (max-width: 520px) {
  .slide {
    padding: 0;
  }
  .slide h2 {
    align-self: flex-start;
  }
  .slick-arrow {
    width: 30px;
    height: 32px;
  }
  .slick-prev {
    left: 0px;
  }
}
