.services {
  align-items: center;
}
.services .section {
  max-width: 900px;
}
.services .section h1 {
  margin-bottom: 20px;
}
.services .section h4 {
  margin-bottom: 80px;
  max-width: 700px;
  color: #333;
}
.services .section .service-block {
  margin-bottom: 60px;
}
.services .section .service-block .cols .two-col {
  width: 54%;
}
.services .section .service-block .cols .two-col.image {
  width: 40%;
}
.services .section .service-block .cols .two-col div > img {
  align-self: center;
}
.services a.cta {
  align-self: flex-start;
}
@media all and (max-width: 920px) {
  .services .section .service-block .cols .two-col {
    width: 100%;
  }
  .services .section .service-block .cols .two-col.image {
    width: 100%;
  }
}
