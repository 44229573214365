.footer {
  /* flex-direction: row;
  justify-content: space-between; */
  /* background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#ac7171),
    color-stop(99%, #521da7)
  );
  background: linear-gradient(180deg, #ac7171 0, #521da7 99%); */
  background-color: var(--color-purple-pale);
  width: 100%;
  align-items: center;
  margin-bottom: 0;
}
.footer .section {
  align-items: flex-start;
}
.footer p,
.footer h4 {
  /* color: #fff; */
}
.footer a {
  color: #333;
}
.footer a.no-decor:hover {
  border-bottom: none;
}
.footer .svg-inline--fa {
  height: 30px;
}
.footer .svg-inline--fa path {
  color: var(--color-purple-main);
}
.footer .social {
  display: flex;
}
.footer .mailto {
  background-color: var(--color-purple-main);
  height: 27px;
  width: 27px;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -1px;
  margin-left: 10px;
  border-radius: 3px;
}
.footer .mailto .svg-inline--fa {
  height: 15px;
  top: 1px;
  position: relative;
}
.footer .mailto .svg-inline--fa path {
  color: #fff;
}
.footer img.logo {
  /* margin-bottom: 10px; */
  position: relative;
  left: -4px;
}
.footer .menu {
  flex-direction: row;
  align-items: center;
}
.footer .menu a {
  margin-left: 30px;
  text-decoration: none;
}
.footer .menu a.active {
  margin-left: 30px;
  color: var(--color-purple-main);
}

@media all and (max-width: 520px) {
  .footer.section {
    flex-direction: column;
    border-top: 1px solid #bbb;
    padding-top: 50px;
  }
}
