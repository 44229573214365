.ui-wrapper {
  position: relative;
}
.logo-link {
  display: flex;
}
.logo-link:hover {
  text-decoration: none;
  border-bottom: 2px solid transparent;
}
.logo {
  /* width: 50px; */
  height: 40px;
  border-radius: 25px;
}
.content-wrapper {
  /* padding: 40px 0px; */
  align-self: center;
  width: 100%;
}
.content-wrapper.fixed {
  padding-top: 80px;
}
.content-padding {
  padding-left: 30px;
  padding-right: 30px;
}
.content-image {
  border-radius: 20px;
}
a {
  border-bottom: 2px solid transparent;
}
a:hover {
  border-bottom: 2px solid var(--color-purple-main);
}
a.cta,
button.cta {
  background: var(--color-purple-main);
  color: #fff;
  font-weight: 500;
  padding: 12px 25px;
  -webkit-transition: transform 0.5s ease, background 0.3s ease;
  transition: transform 0.5s ease, background 0.3s ease;
  text-align: center;
  align-self: center;
  margin-top: 20px;
  border: none;
  transform: scale(1);
  border-radius: 30px !important;
  cursor: pointer;
}
a.cta:hover,
button.cta:hover {
  transform: scale(1.05);
  -webkit-transition: transform 0.5s ease, background 0.3s ease;
  transition: transform 0.5s ease, background 0.3s ease;
}
button.download-cta {
  color: #fff;
  font-weight: 500;
  border: 2px solid var(--color-purple-main);
  padding: 8px 15px;
  border-radius: 30px;
  transition: transform 0.5s ease, background 0.3s ease;
  text-align: center;
  max-width: 154px;
  background-color: var(--color-purple-main);
  cursor: pointer;
  transform: scale(1);
}
button.download-cta:hover {
  color: #fff;
  background-color: var(--color-purple-main);
  transform: scale(1.05);
  transition: transform 0.5s ease, background 0.3s ease;
}
.cta.simple {
  background: none;
  border: 2px solid var(--color-purple-main);
  color: var(--color-purple-main);
}
.cta.simple:hover {
  background: var(--color-purple-main);
  color: #fff;
  transform: unset;
}
a.arrow {
  content: ">";
  color: var(--color-purple-main);
  align-items: center;
  position: relative;
  align-self: flex-start;
  padding-right: 20px;
}
a.arrow::after {
  content: ">";
  margin-left: 10px;
  font-size: 20px;
  position: absolute;
  right: 0;
  top: -2px;
}
.cols {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  max-width: 1366px;
  width: 100%;
}
.one-col {
  width: 100%;
  justify-content: flex-start;
}
.two-col {
  width: 47%;
  justify-content: flex-start;
}
.three-col {
  width: 30%;
  justify-content: flex-start;
}
.four-col {
  width: 20%;
  justify-content: flex-start;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  z-index: 3;
}
.modal > .inner {
  position: absolute;
  /* top: 150px;
  right: calc(100vw / 2 - 250px); */
  width: 100%;
  height: 100%;
  max-width: 500px;
  max-height: 300px;
  background: var(--color-purple-main);
  transition: opacity 1s ease;
  opacity: 0;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
}
.modal.show > .inner {
  opacity: 1;
  transition: opacity 1s ease;
}
.modal .app-btn-wrp {
  margin-top: 20px;
}
.modal h2 {
  margin-bottom: 30px;
  color: #fff;
}
.mobile-menu {
  display: none;
}
.mobile-menu {
  position: fixed;
  z-index: 3;
  top: 0px;
  right: -100vw;
  /* transform: translate3d(100vw, 0, 0); */
  width: 100vw;
  height: calc(100vh);
  /* transition: transform 0.5s cubic-bezier(0, 0.52, 0, 1); */
  background: var(--color-purple-main);
  opacity: 0;
  transition: opacity 0.3s ease;
}
.mobile-menu.fixed {
  height: calc(100vh);
}
.mobile-menu.active {
  display: flex;
  right: 0;
  /* transform: translate3d(10vw, 0, 0);
  transition: transform 0.3s ease; */
  opacity: 1;
  transition: opacity 0.3s ease;
}
@media all and (max-width: 920px) {
  .mobile-menu .mobile-menu-close {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
  }
  .mobile-menu .mobile-menu-close svg path {
    color: #fff;
  }
  .mobile-menu .links {
    padding: 0 20px;
  }
  .mobile-menu .links a {
    color: #fff;
    font-size: 24px;
    margin-bottom: 20px;
  }
  .mobile-menu .links a.active {
    /* padding-left: 20px; */
    font-weight: 600;
  }
  .mobile-menu .links a.active::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    font-family: "Font Awesome 6 Free";
    font-weight: 300;
    content: "\f061";
    margin-right: 10px;
  }
}
@media all and (max-width: 520px) {
  .logo {
    height: 30px;
  }
  .content-wrapper {
    padding-top: 0;
  }
  .modal > .inner {
    width: 90vw;
    height: 60vw;
    right: 5vw;
    padding: 0 20px;
    margin-bottom: 40px;
  }
  .modal > .inner .app-btn-wrp {
    justify-content: space-around;
    margin-top: 10px;
  }

  .modal > .inner .app-btn-wrp p {
    color: #fff;
  }

  .modal > .inner .app-btn-wrp .app-btn {
    max-height: 44px;
  }
  .modal > .inner h2 {
    font-size: 30px;
  }
  .cols {
    flex-direction: column;
  }
  .two-col,
  .three-col,
  .four-col {
    width: 100%;
    margin-bottom: 25px;
  }
  .cols > .empty {
    display: none;
  }
  button.download-cta:hover {
    background-color: transparent;
  }
}
