:root {
  --color-purple-dark: #7544a7;
  --color-purple-main: #78408f;
  --color-purple-medium: #945f8d;
  --color-purple-light: #e8d3e5;
  --color-purple-pale: #eee8f3;
  --color-blue-dark: #0c0c20;
  --color-blue-light: #212139;
  --color-blue-medium: #66b9ed;
  --color-blue-link: #3e90ff;
  --color-blue-rain: #4870a6;
}
