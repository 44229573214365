.team .four-col {
  align-items: center;
  text-align: center;
}
.team .desc {
  text-transform: uppercase;
  color: var(--color-purple-dark);
  font-size: 14px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.team h4 {
  font-size: 21px;
}
.team > h3 {
  margin-bottom: 15px;
}
img.team {
  border-radius: 200px;
  max-width: 300px;
  width: 100%;
  margin-bottom: 20px;
}
.social {
  margin-top: 20px;
}
.social:hover {
  border-color: transparent;
}
.social img {
  max-width: 30px;
}
